import React, { Component, ReactNode } from 'react';
import {
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';

interface Props {
  isOpen?: boolean;
  title?: ReactNode;
  onClose?: () => void;
  importOptionsFromClipboard: (value: string) => void;
  removeClipboardOptions: (value: string) => void;
  append?: boolean;
}

export class AppendFromClipboardModal extends Component<Props> {
  handleClose = () => {
    const { onClose } = this.props;

    if (onClose) {
      onClose();
    }
  };

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.handleClose}
        className="optimus-modal"
      >
        <ModalHeader toggle={this.handleClose}>{`${
          this.props.append ? 'Append Clipboard' : 'Remove Clipboard'
        }`}</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="pasteBox">Paste into the box below</Label>
            <Input
              type="textarea"
              id="pasteBox"
              placeholder={`Paste (Ctrl/Cmd + V) into this box to ${
                this.props.append ? 'append' : 'remove'
              } items`}
              onPaste={event => {
                const data = event.clipboardData.getData('Text');
                if (this.props.append) {
                  this.props.importOptionsFromClipboard(data);
                } else {
                  this.props.removeClipboardOptions(data);
                }

                this.handleClose();
              }}
            />
          </FormGroup>
        </ModalBody>
      </Modal>
    );
  }
}
