import classNames from 'classnames';
import React, {
  CSSProperties,
  FunctionComponent,
  MouseEvent,
  ReactNode,
} from 'react';

interface Props {
  className?: string;
  style?: CSSProperties;
  inline?: boolean;
  id: string;
  label?: ReactNode;
  checked?: boolean;
  indeterminate?: boolean;
  onClick?: (event: MouseEvent<HTMLInputElement>) => void;
}

const CustomCheckbox: FunctionComponent<Props> = props => (
  <div
    onClick={props.onClick}
    className={classNames([
      props.className,
      'custom-control',
      'custom-checkbox',
      'extended-custom-checkbox',
      { 'custom-control-inline': props.inline },
    ])}
    style={props.style}
  >
    <input
      type="checkbox"
      className="custom-control-input"
      id={props.id}
      ref={ref => {
        if (ref) {
          ref.checked = !!props.checked;
          ref.indeterminate = !props.checked && !!props.indeterminate;
        }
      }}
    />
    <label className="custom-control-label" htmlFor={props.id}>
      {props.label || ''}
    </label>
  </div>
);

export default CustomCheckbox;
