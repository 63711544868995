import { TestState } from '../components/CohortedTestForm/CohortedTestForm';
import { FeatureState } from '../components/CohortedTestForm/Feature/featureSlice';
import { GameEdition, TargetType } from '../components/SelectField/SelectField';
import { CohortedTestsState } from '../pages/CohortedTestsPage/cohortedTestsPageSlice';

export interface CohortedTest {
  groups?: any[];
  segment_sequence_id?: string | null;
  country_codes?: string[];
  completed_on?: number | null;
  target_type?: TargetType;
  object_type?: 'cohorted_test';
  features?: any[];
  created_on?: number;
  category_id: string;
  status_per_country?: any[];
  client_id: string | null;
  game_edition_id: GameEdition;
  target?: string | null;
  description: string;
  status: TestState;
  target_day: number;
  game_id: string;
  updated_on?: number | null;
  name: string;
  paused_on?: number | null;
  object_id?: string;
  activated_on?: number | null;
  game_edition_ids?: any[] | null;
  acquisition_limit?: number | null;
  deleted_on?: number | null;
}

export interface Variant {
  [id: string]: Settings[];
}

export interface Settings {
  settings: any;
}

export interface GlobalState {
  cohortedTestsPageReducer: CohortedTestsState;
  featureReducer: FeatureState;
}

export enum ValueType {
  STRING = 'string',
  OBJECT = 'object',
  ARRAY = 'array',
  INT = 'int',
  DOUBLE = 'double',
  BOOLEAN = 'boolean',
  NULL = 'null',
}
