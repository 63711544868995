import React, { Component, ReactNode } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

interface Props {
  isOpen?: boolean;
  title?: ReactNode;
  confirmColor?: string;
  confirmContent?: ReactNode;
  denyColor?: string;
  denyContent?: ReactNode;
  onClose?: () => void;
  onResolve?: (resolved: boolean) => void;
  disablePrimaryButton?: boolean;
}

export class ConfirmationModal extends Component<Props> {
  handleCancel = () => {
    const { onResolve } = this.props;

    if (onResolve) {
      onResolve(false);
    }
  };

  handleConfirm = () => {
    const { onResolve } = this.props;

    if (onResolve) {
      onResolve(true);
    }
  };

  handleClose = () => {
    const { onClose } = this.props;

    if (onClose) {
      onClose();
    }
  };

  render() {
    const { children, title } = this.props;

    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.handleClose}
        className="optimus-modal"
      >
        <ModalHeader toggle={this.handleClose}>{title}</ModalHeader>
        <ModalBody>{children}</ModalBody>
        <ModalFooter>
          <Button
            disabled={this.props.disablePrimaryButton}
            color={this.props.confirmColor || 'primary'}
            onClick={this.handleConfirm}
          >
            {this.props.confirmContent || 'Yes'}
          </Button>
          <Button
            className="ml-1"
            color={this.props.denyColor || 'secondary'}
            onClick={this.handleCancel}
          >
            {this.props.denyContent || 'No'}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
